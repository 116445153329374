import React from "react";
import classes from "./Contacts.module.css";
import informationInSeveralLanguageContacts from "./ContactsHelpers";
import photo from "./../../../../img/photo1.png";

const Contacts = ({ lang }) => {
  return (
    <div className={classes.container}>
      <div>
        <img className={classes.photo} src={photo} alt="my-phot"></img>
      </div>
      <div className={classes.inform}>
        <p>{informationInSeveralLanguageContacts[lang].p}</p>
        <ul className={classes.summary} type="">
          <li>
            <a href="tel: +375295099254">
              <img
                className={classes.icon}
                src={informationInSeveralLanguageContacts.icon.tel}
                alt="tel"
              ></img>
              {informationInSeveralLanguageContacts[lang].li["tel"]}
            </a>
          </li>
          <li>
            <a href="mailto: znachki@fotodrug.by">
              <img
                className={classes.icon}
                src={informationInSeveralLanguageContacts.icon.email}
                alt="email"
              ></img>
              {informationInSeveralLanguageContacts[lang].li["email"]}
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/fotodrugby/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={classes.icon}
                src={informationInSeveralLanguageContacts.icon.instagram}
                alt="instagram"
              ></img>
              {informationInSeveralLanguageContacts[lang].li["instagram"]}
            </a>
          </li>
          <li>
            <img
              className={classes.icon}
              src={informationInSeveralLanguageContacts.icon.adress}
              alt="adress"
            ></img>
            {informationInSeveralLanguageContacts[lang].li["adress"]}
          </li>
          <li>
            <a
              href={informationInSeveralLanguageContacts[lang].href}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={classes.icon}
                src={informationInSeveralLanguageContacts.icon.cv}
                alt="cv"
              ></img>
              {informationInSeveralLanguageContacts[lang].li["cv"]}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Contacts;
