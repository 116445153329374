import React from "react";
import classes from "./InformReg.module.css";
import informRegInSeveralLanguage from "./InformRegHelpers";

const InformReg = ({ lang }) => {
  return (
    <div className={classes.text}>
      <div>{informRegInSeveralLanguage[lang].p1}</div>
      <div>{informRegInSeveralLanguage[lang].p2}</div>
    </div>
  );
};
export default InformReg;
