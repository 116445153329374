import React from "react";
import classes from "./MenuList.module.css";
import { menuList } from "./MenuListHelpers";
import dog from "../../../img/photo.jpg";

const MenuList = ({ lang, onClick }) => {
  return (
    <nav>
      <ul className={classes.container}>
        {menuList(lang).map((el, ind) => (
          <li className={classes.link} key={el}>
            <div onClick={onClick} id={`${menuList("en")[ind]}Menu`}>
              {el}
            </div>
          </li>
        ))}
        <li>
          <img className={classes.logo} src={dog} alt="fotodrug"></img>
        </li>
      </ul>
    </nav>
  );
};

export default MenuList;
