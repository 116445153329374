import React from "react";
import classes from "./AllInformation.module.css";
import BlockInformation from "./../BlockInformation/BlockInformation";
import informations from "./AllInformatiohHelpers";

const AllInformation = ({ lang }) => {
  const informTitle = informations(lang).map((el) => (
    <BlockInformation
      id={el.id}
      title={<span>{el.title}</span>}
      inform={el.inform}
      key={el.id}
    />
  ));
  return <div className={classes.container}>{informTitle}</div>;
};

export default AllInformation;
