import tel from "./../../../../icon/tel.png";
import email from "./../../../../icon/email.png";
import adress from "./../../../../icon/adress.png";
import cv from "./../../../../icon/download.png";
import instagram from "./../../../../icon/instagram.png";

const belInform = {
  p: "Звязацца з намі:",
  li: {
    tel: "+375 29 509 92 54",
    email: "znachki@fotodrug.by",
    adress: "Мінск, пр-т Газеты Праўда, 14",
    cv: "Спампаваць прапанову ",
    instagram: "Instagram/fotodrugby",
  },
  name: "",
  href: "https://drive.google.com/file/d/1ELlft7M70qzZ-UqbdoCy_9Jn8pWV_ipa/view?usp=drive_link",
};
const ruInform = {
  p: "Связаться с нами:",
  li: {
    tel: "+375 29 509 92 54",
    email: "znachki@fotodrug.by",
    adress: "Минск, пр-т Газеты Правда, 14",
    cv: "Скачать предложение",
    instagram: "Instagram/fotodrugby",
  },
  name: "",
  href: "https://drive.google.com/file/d/1SPFNiPw2CEwfVP8MUpzvIr8MqEFzBPLT/view?usp=drive_link",
};

const icon = {
  tel: tel,
  email: email,
  adress: adress,
  cv: cv,
  instagram: instagram,
};

const informationInSeveralLanguageContacts = {
  bel: belInform,
  ru: ruInform,
  icon: icon,
};

export default informationInSeveralLanguageContacts;
