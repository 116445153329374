import React from "react";
import classes from "./LanguageVariants.module.css";

const LanguageVariants = ({ value, handleChange }) => {
  const language = ["bel", "ru"];
  return (
    <select className={classes.variants} value={value} onChange={handleChange}>
      {language.map((el, ind) => (
        <option key={"lang" + ind}>{el}</option>
      ))}
    </select>
  );
};

export default LanguageVariants;
