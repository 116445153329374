import React from "react";
import classes from "./Price.module.css";
import {
  informationInSeveralLanguagePrice,
  columns,
  row25,
  row37,
  row56,
  size,
  magn,
} from "./PriceHelpers";

const Price = ({ lang }) => {
  return (
    <div className={classes.container}>
      <div className={classes.text}>
        {informationInSeveralLanguagePrice[lang].p}
      </div>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <tbody>
            <tr className={classes.tr1}>
              <td className={classes.tdS}>{columns[0]}</td>
              {columns.slice(1).map((el) => (
                <td className={classes.td} key={el}>
                  {el}
                </td>
              ))}
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdS}>{size[lang][25]}</td>
              {row25.map((el) => (
                <td className={classes.td} key={el}>
                  {el}
                </td>
              ))}
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdS}>{size[lang][37]}</td>
              {row37.map((el) => (
                <td className={classes.td} key={el}>
                  {el}
                </td>
              ))}
            </tr>
            <tr className={classes.tr}>
              <td className={classes.tdS}>{size[lang][56]}</td>

              {row56.map((el) => (
                <td className={classes.td} key={el}>
                  {el}
                </td>
              ))}
            </tr>
            <tr className={[classes.tr]}>
              <td className={classes.tdSMagn}>{size[lang].magn}</td>
              {magn.map((el) => (
                <td className={classes.tdMagn} key={el}>
                  {el}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <div className={classes.text}>
        {informationInSeveralLanguagePrice[lang].p2}
      </div>
    </div>
  );
};
export default Price;
