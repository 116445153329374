import two from "../../../../../src/img/projects/2.jpg";
import three from "../../../../../src/img/projects/3.jpg";
import four from "../../../../../src/img/projects/4.jpg";
import five from "../../../../../src/img/projects/5.jpg";
import six from "../../../../../src/img/projects/6.jpg";
import seven from "../../../../../src/img/projects/7.jpg";
import eight from "../../../../../src/img/projects/8.jpg";
import nine from "../../../../../src/img/projects/9.jpg";
import ten from "../../../../../src/img/projects/10.jpg";
import eleven from "../../../../../src/img/projects/11.jpg";
import twelve from "../../../../../src/img/projects/12.jpg";
import thirteen from "../../../../../src/img/projects/13.jpg";

const projects = {
  two: {
    name: "two",
    img: two,
  },
  thirteen: {
    name: "thirteen",
    img: thirteen,
  },
  four: {
    name: "four",
    img: four,
  },
  five: {
    name: "five",
    img: five,
  },
  six: {
    name: "six",
    img: six,
  },
  seven: {
    name: "seven",
    img: seven,
  },
  eight: {
    name: "eight",
    img: eight,
  },
  nine: {
    name: "nine",
    img: nine,
  },
  ten: {
    name: "ten",
    img: ten,
  },
  eleven: {
    name: "eleven",
    img: eleven,
  },
  twelve: {
    name: "twelve",
    img: twelve,
  },
  three: {
    name: "three",
    img: three,
  },
};

export default projects;
