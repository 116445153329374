const linkConst = [
  {
    bel: {
      start: "У пачатак",
      price: "Цэны",
      projects: "Галерэя",
      contacts: "Кантакты",
      // dowland: "Спампаваць",
    },
    ru: {
      start: "В начало",
      price: "Цены",
      projects: "Галерея",
      contacts: "Контакты",
      // dowland: "Скачать",
    },
    en: {
      start: "Start",
      price: "Prices",
      projects: "Projects",
      contacts: "Contacts",
      // dowland: "Dowland",
    },
  },
];

const menuList = (language) => {
  const objectlinkChoseLanguage = linkConst.map((el) => el[language]);
  return Object.keys(objectlinkChoseLanguage[0]).map(
    (el) => objectlinkChoseLanguage[0][el]
  );
};

export { menuList, linkConst };
