const belInform = {
  ul1: "Заказ можна аформіць:",
  li1: "па электроннай пошце ",
  li2P1: "па телефоне: ",
  li2P2: " (можна пісаць ці званіць на Viber) – кантактная асоба Жанна,",
  li3P1: "напісаць нам у сац.сетцы: VK - ",
  li3P2: "ці Instagram - ",
  ul2: "Атрыманне заказу:",
  ul2P1: "самавываз – г. Мінск, вул. Шаранговіча, д. 63 к. 2 ,",
  ul2P2:
    "перасылка Белпоштай – бескаштоўнай пасылкай (перасылку аплачвае заказчык при атрыманні на пошце).",
  ul3: "Спосабы аплаты:",
  ul3P1: "безнаяўны разлік (на разліковы рахунак па ЕРІП),",
  ul3P2: "безнаяўны разлік (па дагаворы на разліковы рахунак для юр. асоб).",
  p: "Гарантуем вам 100% якасны заказ! Мы правяраем кожную шпільку, кожную нарыхтоўку, а пасля вырабу заўсёды пералічваем колькасць і правяраем якасць абсалютна кожнага значка.",
};
const ruInform = {
  ul1: "Заказ можно оформить:",
  li1: "по элетронной почте ",
  li2P1: "по телефону: ",
  li2P2: " (можно писать или звонить на Viber) – контактное лицо Жанна,",
  li3P1: "написать нам в соц.сети: VK - ",
  li3P2: "или Instagram - ",
  ul2: "Получение заказа: ",
  ul2P1: "самовывоз – г. Минск, ул. Шаранговича, д. 63 к. 2 ,",
  ul2P2:
    "пересылка Белпочтой – бесценной посылкой (пересылку оплачивает заказчик при получении на почте).",
  ul3: "Способы оплаты:",
  ul3P1: "безналичная оплата (на расчетный счет через ЕРИП),",
  ul3P2: "безналичная оплата (по договору на расчетный счет для юр.лиц).",
  p: "Гарантируем Вам 100% качественный заказ! Мы проверяем каждую булавку, каждую заготовку, а после изготовления всегда пересчитываем количество и проверяем качество абсолютно каждого значка.",
};

const informationInSeveralLanguagePrice = {
  bel: belInform,
  ru: ruInform,
  email: "znachki@fotodrug.by",
  tel: "+375 29 5099254",
  vk: "vk.com/fotodrugminsk ",
  inst: "instagram.com/fotodrugby",
};

export default informationInSeveralLanguagePrice;
