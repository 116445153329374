const belInformReg = {
  p1: "Аказанне паслуг: ІП Боган Дз.В.",
  p2: "УНП 691058942",
};
const ruInformReg = {
  p1: "Оказание услуг: ИП Боган Д.В.",
  p2: "УНП 691058942",
};

const informRegInSeveralLanguage = {
  bel: belInformReg,
  ru: ruInformReg,
};

export default informRegInSeveralLanguage;
