import React from "react";
import classes from "./Start.module.css";
import photo from "../../../../img/projects/photoZnachki.jpg";
import contacts from "./StartHelpers";

const Start = ({ lang }) => {
  return (
    <div className={classes.container}>
      <div className={classes.inform}>
        <ul className={classes.summary} type="">
          <li>
            <a href="tel: +375295099254">
              <img
                className={classes.icon}
                src={contacts.icon.tel}
                alt="tel"
              ></img>
              {contacts.ru.li["tel"]}
            </a>
          </li>
          <li>
            <a href="mailto: znachki@fotodrug.by">
              <img
                className={classes.icon}
                src={contacts.icon.email}
                alt="email"
              ></img>
              {contacts.ru.li["email"]}
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/fotodrugby/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={classes.icon}
                src={contacts.icon.instagram}
                alt="instagram"
              ></img>
              {contacts.ru.li["instagram"]}
            </a>
          </li>
        </ul>
      </div>
      <div>
        <img className={classes.photo} src={photo} alt="my-phot"></img>
      </div>
    </div>
  );
};

export default Start;
