import React from "react";
import classes from "./Inform.module.css";
import informationInSeveralLanguagePrice from "./InformHelpers";

const Inform = ({ lang }) => {
  return (
    <div className={classes.text}>
      <ul className={classes.inform}>
        <p>{informationInSeveralLanguagePrice[lang].ul1}</p>
        <li>
          {informationInSeveralLanguagePrice[lang].li1}
          <a href="mailto: znachki@fotodrug.by">
            {informationInSeveralLanguagePrice.email}
          </a>
          ,
        </li>
        <li>
          {informationInSeveralLanguagePrice[lang].li2P1}
          <a href="tel: +375295099254">
            {informationInSeveralLanguagePrice.tel}
          </a>
          {informationInSeveralLanguagePrice[lang].li2P2}
        </li>
        <li>
          {informationInSeveralLanguagePrice[lang].li3P1}
          <a
            href="https://www.vk.com/fotodrugminsk"
            target="_blank"
            rel="noreferrer"
          >
            {informationInSeveralLanguagePrice.vk}
          </a>
          {informationInSeveralLanguagePrice[lang].li3P2}
          <a
            href="https://www.instagram.com/fotodrugby"
            target="_blank"
            rel="noreferrer"
          >
            {informationInSeveralLanguagePrice.inst}
          </a>
          .
        </li>
      </ul>
      <ul className={classes.inform}>
        <p>{informationInSeveralLanguagePrice[lang].ul2}</p>
        <li>{informationInSeveralLanguagePrice[lang].ul2P1}</li>
        <li>{informationInSeveralLanguagePrice[lang].ul2P2}</li>
      </ul>
      <ul className={classes.inform}>
        <p>{informationInSeveralLanguagePrice[lang].ul3}</p>
        <li>{informationInSeveralLanguagePrice[lang].ul3P1}</li>
        <li>{informationInSeveralLanguagePrice[lang].ul3P2}</li>
      </ul>
    </div>
  );
};
export default Inform;
