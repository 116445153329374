import React from "react";
import classes from "./header.module.css";
import MenuList from "../../common/MenuList/MenuList";
import LanguageVariants from "../../common/LanguageVariants/LanguageVariants";

const Header = ({ lang, change, onClick }) => {
  return (
    <header className={classes.header}>
      <div className={classes.container}>
        <div>
          <LanguageVariants value={lang} handleChange={change} />
        </div>
        <MenuList lang={lang} onClick={onClick} />
      </div>
    </header>
  );
};

export default Header;
