import React from "react";
import Price from "../../containers/BlocksWithInformation/Price/Price";
import Contacts from "../../containers/BlocksWithInformation/Contacts/Contacts";
import Projects from "../../containers/BlocksWithInformation/Projects/Projects";
import Start from "../../containers/BlocksWithInformation/Start/Start";
import Inform from "../../containers/BlocksWithInformation/Inform/Inform";
import InformReg from "../../containers/BlocksWithInformation/InformReg/InformReg";

const informationInSeveralLanguage = [
  {
    bel: {
      start: { id: "Start", inform: <Start /> },
      price: {
        id: "Prices",
        title: "Цэны",
        inform: <Price lang={"bel"} />,
      },

      inform: {
        id: "Inform",
        title: "Аформіць і атрымаць заказ:",
        inform: <Inform lang={"bel"} />,
      },
      projects: {
        id: "Projects",
        title: "Галерэя работ",
        inform: <Projects />,
      },
      contacts: {
        id: "Contacts",
        title: "Кантакты",
        inform: <Contacts lang={"bel"} />,
      },
      informReg: {
        id: "InformReg",
        inform: <InformReg lang={"bel"} />,
      },
    },
    ru: {
      start: {
        id: "Start",
        inform: <Start />,
      },
      price: {
        id: "Prices",
        title: "Цены",
        inform: <Price lang={"ru"} />,
      },
      inform: {
        id: "Inform",
        title: "Оформить и получить заказ",
        inform: <Inform lang={"ru"} />,
      },
      projects: {
        id: "Projects",
        title: "Галерея работ",
        inform: <Projects />,
      },
      contacts: {
        id: "Contacts",
        title: "Контакты",
        inform: <Contacts lang={"ru"} />,
      },
      informReg: {
        id: "InformReg",
        inform: <InformReg lang={"ru"} />,
      },
    },
    en: {
      start: { id: "Start", inform: <Start /> },
      price: {
        id: "Prices",
        title: "Prices",
        inform: <Price lang={"en"} />,
      },

      projects: { id: "Projects", title: "Projects", inform: <Projects /> },
    },
    contacts: {
      id: "Contacts",
      title: "Contacts",
      inform: <Contacts lang={"en"} />,
    },
    informReg: {
      id: "InformReg",
      inform: <InformReg lang={"en"} />,
    },
  },
];
const informations = (language) => {
  const objectlinkChoseLanguage = informationInSeveralLanguage.map(
    (el) => el[language]
  );
  return Object.keys(objectlinkChoseLanguage[0]).map(
    (el) => objectlinkChoseLanguage[0][el]
  );
};

export default informations;
