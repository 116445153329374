import React, { useState, useEffect } from "react";
import Header from "./components/containers/header/header";
import classes from "./App.module.css";
import AllInformation from "./components/common/AllInformation/AllInformation";
//import menuList from "./components/common/MenuList/MenuListHelpers";

function App() {
  const [language, setLang] = useState("ru");
  const onChange = (e) => {
    setLang(e.target.value);
  };
  const scrollTo = (e) => {
    const scrollToDiv = e.target.id.slice(0, -4);
    window.location.replace(`./#${scrollToDiv}`);
    e.preventDefault();
  };
  useEffect(() => {}, []);
  return (
    <div className={classes.wrapper}>
      <header className={classes.header}>
        <Header lang={language} change={onChange} onClick={scrollTo} />
      </header>
      <main className={classes.main}>
        <AllInformation lang={language} />
      </main>
    </div>
  );
}

export default App;
