import tel from "./../../../../icon/tel.png";
import email from "./../../../../icon/email.png";
import instagram from "./../../../../icon/instagram.png";

const belInform = {
  li: {
    tel: "+375 29 509 92 54",
    email: "znachki@fotodrug.by",
    instagram: "Instagram/fotodrugby",
  },
};
const ruInform = {
  li: {
    tel: "+375 29 509 92 54",
    email: "znachki@fotodrug.by",
    instagram: "Instagram/fotodrugby",
  },
};

const icon = {
  tel: tel,
  email: email,
  instagram: instagram,
};

const contacts = {
  bel: belInform,
  ru: ruInform,
  icon: icon,
};

export default contacts;
