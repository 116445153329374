import React from "react";
import classes from "./Projects.module.css";
import projects from "./ProjectsHelpers";

const OneProject = ({ project }) => {
  return (
    <div className={classes.block}>
      <div className={classes.singleProject}>
        <figure className={classes.image}>
          <img src={projects[project].img} alt={projects[project].name}></img>
        </figure>
      </div>
    </div>
  );
};

const arrayProjects = Object.keys(projects);

const Projects = () => {
  const all = arrayProjects.map((el) => (
    <>
      <OneProject project={el} />
    </>
  ));

  return (
    <div className={classes.photoes}>
      <div className={classes.block}>{all}</div>
    </div>
  );
};

export default Projects;
