const belInform = {
  p: "Прапануем выраб закатных значкоў з эмблемай (лагатыпам, фота) на прышчэпке ці магніце па лепшых ценах. Ніжэй у табліцы ёсць магчымасць пазнаёміцца з цэнамі на 11 ліпеня 2024 года",
  p2: "Гарантуем вам 100% якасны заказ! Мы правяраем кожную шпільку, кожную нарыхтоўку, а пасля вырабу заўсёды пералічваем колькасць і правяраем якасць абсалютна кожнага значка.",
};
const ruInform = {
  p: "Предлагаем изготовление закатных значков с эмблемой (логотипом, фото) на булавке или магните по приятным ценам. Ниже в таблице вы можете ознакомиться с актуальными ценами на 11 июля 2024 года",
  p2: "Гарантируем Вам 100% качественный заказ! Мы проверяем каждую булавку, каждую заготовку, а после изготовления всегда пересчитываем количество и проверяем качество абсолютно каждого значка.",
};

const informationInSeveralLanguagePrice = {
  bel: belInform,
  ru: ruInform,
};

const size = {
  ru: { 25: "25 мм", 37: "37 мм", 56: "56 мм", magn: "на магните 56 мм" },
  bel: { 25: "25 мм", 37: "37 мм", 56: "56 мм", magn: "на магніце \n 56 мм" },
};

const columns = [
  "",
  "1-5 шт",
  "6-10 шт",
  "11-49 шт",
  "50-99 шт",
  "100-499 шт",
  "500-999 шт",
  "1000-2999 шт",
];
const row25 = [
  "6,00 р",
  "4,00 р",
  "3,00 р",
  "2,60 р",
  "1,90 р",
  "1,60 р",
  "1,45 р",
];
const row37 = [
  "6,20 р",
  "4,20 р",
  "3,20 р",
  "2,80 р",
  "2,10 р",
  "1,80 р",
  "1,65 р",
];
const row56 = [
  "6,40 р",
  "4,40 р",
  "3,40 р",
  "3,00 р",
  "2,20 р",
  "1,90 р",
  "1,75 р",
];
const magn = [
  "9,40 р",
  "7,40 р",
  "6,40 р",
  "6,00 р",
  "5,20 р",
  "4,90 р",
  "4,75 р",
];

export {
  informationInSeveralLanguagePrice,
  columns,
  row25,
  row37,
  row56,
  size,
  magn,
};
