import React from "react";
import classes from "./BlockInformation.module.css";

const BlockInformation = ({ title, inform, id }) => {
  return (
    <section id={id} className={classes.container}>
      {title && <div className={classes.title}>{title}</div>}
      <div className={classes.inform}>{inform}</div>
    </section>
  );
};

export default BlockInformation;
